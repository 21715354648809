import { select, json } from "d3";
import debounce from "debounce";
import NetworkVisualization from "../network-visualization/NetworkVisualization";
import Modal from "../modal/modal";

const settings = {
  width: { min: 650, max: 1000 },
  height: { min: 650, max: 1000 },
};

// Get div width
const getContainerWidth = () => {
  // Get container width
  const width = select("#visualContainer").node().getBoundingClientRect().width;
  return width > settings.width.max
    ? settings.width.max
    : width < settings.width.min
    ? settings.width.min
    : width;
};

const getHeight = () => {
  // Get the offset of the dropdown container to fit the visual within the screen
  const offsetTop = select("#dropdownContainer").node().getBoundingClientRect();
  const height = window.innerHeight - (offsetTop.height + offsetTop.top);
  return height > settings.height.max
    ? settings.height.max
    : height < settings.height.min
    ? settings.height.min
    : height;
};

// Check if copy is not null; if so show placeholder
const checkCopy = (input) => {
  return input === null ? "Momenteel niet beschikbaar" : input;
};

class Controller {
  constructor() {
    // Show loading
    select("#loadingIndicator").classed("hide", false);

    // Load content
    json("https://api.utrechtregion-digitaletransformatie.nl/").then((data) => {
      this.initialize(data);
    });
  }

  initialize(data) {
    const filters = {
      role: {},
      region: {},
      expertise: {},
      social_challenge: {},
    };

    // Create visual
    this.visual = new NetworkVisualization(
      this,
      "#visualizationContainer",
      getContainerWidth(),
      getHeight(),
      data
    );

    this.modal = new Modal();

    const resizeVisual = debounce(() => {
      this.visual.resize(getContainerWidth(), getHeight());
    }, 250);

    // Resize the visual
    window.addEventListener("resize", () => resizeVisual(), false);

    // Add dropdown options
    select("#dropdownFilterRoles")
      .selectAll(".option")
      .data(data.filters.roles, (d, index) => {
        // Add id to make sure these correspond within the dropdown and the visual
        d.id = index;
        filters.role[d.id] = d.name;
      })
      .enter()
      .append("option")
      .attr("class", "option")
      .text((d) => d.name)
      .attr("value", (d) => d.id);

    // Add dropdown options
    select("#dropdownFilterRegions")
      .selectAll(".option")
      .data(data.filters.regions, (d, index) => {
        // Add id to make sure these correspond within the dropdown and the visual
        d.id = index;
        filters.region[d.id] = d.name;
      })
      .enter()
      .append("option")
      .attr("class", "option")
      .text((d) => d.name)
      .attr("value", (d) => d.id);

    // Adding ability to open modal
    select("#modalButton").on("click", () => {
      const nodes = data.nodes.filter((x) =>
        this.relatedNodeIds.includes(x.id)
      );

      data.nodes
        .filter((x) => x.type == "social_challenge")
        .forEach((x) => (filters.social_challenge[x.id] = x.name));
      data.nodes
        .filter((x) => x.type == "expertise")
        .forEach((x) => (filters.expertise[x.id] = x.name));

      const mappedFilters = { ...this.visual.state.filter };

      Object.entries(mappedFilters).map(([key, value]) => {
        mappedFilters[key] = filters[key][value] ? filters[key][value] : value;
      });

      this.modal.open(nodes, mappedFilters, this.visual.entities.length);
    });

    // All interaction
    this.initializeInteraction(data);

    // Hide loading
    select("#loadingIndicator").classed("hide", true);
  }

  initializeInteraction(data) {
    select("#site-search").on("change", (event) => {
      const query = event.target.value;
      this.visual.setFilter({
        value: query.toLowerCase(),
        type: "query",
      });
    });

    select("#dropdownFilterRoles").on("change", (event) => {
      const id = select(event.target).node().value;
      const value = parseInt(id) === -1 ? null : parseInt(id);

      this.visual.setFilter({
        id: value,
        type: "role",
      });

      // Reset side panel
      this.updateSidePanel(null);
    });

    select("#dropdownFilterRegions").on("change", (event) => {
      const id = select(event.target).node().value;
      const value = parseInt(id) === -1 ? null : parseInt(id);
      this.visual.setFilter({
        id: value,
        type: "region",
      });

      // Reset side panel
      this.updateSidePanel(null);
    });

    select("#closeSidePanel").on("click", () => {
      document.getElementById("autoComplete").value = "";

      // Reset side panel
      this.updateSidePanel(null);

      // Reset selection
      this.visual.selectNode(null);
      this.visual.force.restart();
      this.visual.draw();
    });
  }

  updateSidePanel(selectedNode) {
    const container = select("#sidePanel");

    // Show hide
    if (selectedNode === null) {
      container.classed("hide", true);
    } else {
      container.classed("hide", false);

      // Update content
      container.selectAll(".title").text(checkCopy(selectedNode.name));
      container.select("#type").text(checkCopy(selectedNode.organization_type));
      container.select("#role").text(checkCopy(selectedNode.role));
      container.select("#region").text(checkCopy(selectedNode.region));
      container
        .select("#description")
        .text(checkCopy(selectedNode.description));

      // Expertise
      let expertiseText = "";
      selectedNode.expertise.forEach((entry, index) => {
        if (index !== selectedNode.expertise.length - 1) {
          expertiseText += entry + "; ";
        } else {
          expertiseText += entry;
        }
      });
      container.select("#expertise").text(checkCopy(expertiseText));

      // Social Challenges
      let challengesText = "";
      selectedNode.challenges.forEach((entry, index) => {
        if (index !== selectedNode.challenges.length - 1) {
          challengesText += entry + "; ";
        } else {
          challengesText += entry;
        }
      });
      container.select("#social").text(checkCopy(challengesText));

      // Determine if URL should be visible
      let websiteURLHtml = "";
      if (selectedNode.url_website !== null) {
        websiteURLHtml = `<a href="${selectedNode.url_website}" target="_blank">${selectedNode.url_website}</a>`;
      }
      container.select("#url").html(websiteURLHtml);

      // Determine if Linkedin icons should be visible
      let linkedinURLHtml = "";
      if (selectedNode.url_contact !== null) {
        linkedinURLHtml = `<a href="${selectedNode.url_contact}" target="_blank">
                  <svg 
                  width="28" 
                  height="28" 
                  viewBox="0 0 16 16" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12.6667 0H3.33333C1.49267 0 0 1.49267 0 3.33333V12.6667C0 14.5073 1.49267 16 3.33333 16H12.6667C14.508 16 16 14.5073 16 12.6667V3.33333C16 1.49267 14.508 0 12.6667 0ZM5.33333 12.6667H3.33333V5.33333H5.33333V12.6667ZM4.33333 4.488C3.68933 4.488 3.16667 3.96133 3.16667 3.312C3.16667 2.66267 3.68933 2.136 4.33333 2.136C4.97733 2.136 5.5 2.66267 5.5 3.312C5.5 3.96133 4.978 4.488 4.33333 4.488ZM13.3333 12.6667H11.3333V8.93067C11.3333 6.68533 8.66667 6.85533 8.66667 8.93067V12.6667H6.66667V5.33333H8.66667V6.51C9.59733 4.786 13.3333 4.65867 13.3333 8.16067V12.6667Z" fill="white"/>
                  </svg>
								</a>`;
      }
      container.select("#linkedin").html(linkedinURLHtml);
    }
  }
}

export default Controller;
