import {
  select,
  scaleLinear,
  forceSimulation,
  forceManyBody,
  forceLink,
  forceCollide,
  forceX,
  forceY,
  min,
  max,
  sum,
  easeQuadIn,
} from "d3";
import autoComplete from "@tarekraafat/autocomplete.js";
import calculateTrigonometric from "../helpers/calculateTrigonometric";

class NetworkVisualization {
  constructor(parent, target, width, height, data) {
    this.parent = parent;
    this.target = target;

    // Use the minium to make sure it's a square
    this.width = Math.min(width, height);
    this.height = Math.min(width, height);

    this.data = data;
    this.entities = data.nodes.filter(
      (x) => x.type !== "social_challenge" && x.type !== "expertise"
    );
    this.parent.relatedNodeIds = this.entities.map((d) => d.id);

    this.search = new autoComplete({
      placeholder: "Zoeken",
      data: {
        src: this.entities,
        keys: ["name"],
      },
      resultItem: {
        highlight: true,
      },
      resultsList: {
        maxResults: this.entities.length,
        noResults: true,
        element: (list, data) => {
          if (!data.results.length) {
            // Create "No Results" message list element
            const message = document.createElement("div");
            message.setAttribute("class", "no_result");
            // Add message text content
            message.innerHTML = `<span>Found No Results for "${data.query}"</span>`;
            // Add message list element to the list
            list.appendChild(message);
          }
        },
      },
      events: {
        input: {
          selection: (event) => {
            const selection = event.detail.selection.value;

            document.getElementById("autoComplete").value = selection.name;

            // Pause force
            this.force.stop();

            // Set hove radius
            selection.r =
              selection.idleRadius + this.settings.nodes.radius.hover;

            this.clickNode(selection);

            // Set hover label
            this.updateLabel(selection);

            // Highlight the nodes and links
            this.updatedRelatedHoverNodes(selection);
          },
        },
      },
    });

    // Set starting position; please note because of the force settings it will appear they "sliding in" from the sides
    this.data.nodes.forEach((d) => {
      d.x = this.width / 2;
      d.y = this.height / 2;
    });
    this.radius = Math.min(this.width / 2, this.height / 2);

    this.state = {
      filter: {
        role: null,
        region: null,
        expertise: null,
        social_challenge: null,
      },
    };

    this.settings = {
      copy: {
        challenge: `Markten`,
        expertise: `Enablers`,
      },
      circles: {
        textPathCircle: 0.96,
        innerCircle: 0.73,
        outerCircle: 0.9,
        padding: 0,
      },
      nodes: {
        radius: {
          minScaleFactor: 0.5, // Min visibility; 7 * 0.5 = 3.5
          maxScaleFactor: 1.0, // Do not increase
          scaleFactor: 0.5,
          min: 7,
          max: 16,
          hover: 5,
          topic: 20,
        },
        padding: 3,
        opacity: {
          // When interaction with the node
          active: 1,
          // Default
          idle: 1,
          // When filter is active and hovering on a specific node; still show which nodes are related within the selected filter
          inactiveFiltered: 0.75,
          // When filter is active and nodes are not included within the filter
          inactive: 0.25,
          // Opacity for filter nodes
          inactiveFilter: 0.33,
        },
      },
      links: {
        opacity: {
          // When hovering on a node
          active: 1,
          // When filter is active and hovering on a specific node; still show which nodes are related within the selected filter
          inactiveFiltered: 0.8,
          // Default
          idle: 0.1,
        },
      },
    };
    this.radiusScale = scaleLinear();
    this.initialize();
  }

  initialize() {
    // Create html container
    this.container = select(this.target)
      .append("div")
      .attr("class", "network-visualization");

    // Add svg
    this.svg = this.container
      .append("svg")
      .attr("class", "svg-container")
      .attr("width", this.width)
      .attr("height", this.height);
    this.svg
      .append("circle")
      .attr("class", "outer-circle")
      .attr(
        "r",
        this.radius * this.settings.circles.outerCircle -
          this.settings.circles.padding
      )
      .attr("transform", () => {
        return `translate(${this.width / 2}, ${this.height / 2})`;
      });
    this.svg
      .append("circle")
      .attr("class", "inner-circle")
      .attr("id", "innerCircle")
      .attr(
        "r",
        this.radius * this.settings.circles.innerCircle -
          this.settings.circles.padding
      )
      .attr("transform", () => {
        return `translate(${this.width / 2}, ${this.height / 2})`;
      });

    // Create text paths
    this.svg
      .append("path")
      .attr("class", "text-path")
      .attr("d", () => {
        // Font-Size / 3  * 2 will correct the font offset
        const textHeightOffset = 16 / 3;
        const r =
          this.radius * this.settings.circles.textPathCircle -
          (this.settings.circles.padding + textHeightOffset);
        return `M
				${this.width / 2 - r},${this.height / 2}
				A ${r},${r} 0 0,1
				${this.width / 2 + r},${this.height / 2}`;
      })
      .attr("id", "upperTextPath");
    this.svg
      .append("path")
      .attr("class", "text-path")
      .attr("d", () => {
        const r =
          this.radius * this.settings.circles.textPathCircle -
          this.settings.circles.padding;
        return `M
				${this.width / 2 - r},${this.height / 2}
				A ${r},${r} 0 0,0
				${this.width / 2 + r},${this.height / 2}`;
      })
      .attr("id", "lowerTextPath");

    // Add text to the paths
    this.svg
      .append("text")
      .append("textPath")
      .attr("xlink:href", "#upperTextPath")
      .attr("class", "info-text social_challenge")
      .attr("startOffset", "50%")
      .text(this.settings.copy.challenge);
    this.svg
      .append("text")
      .append("textPath")
      .attr("xlink:href", "#lowerTextPath")
      .attr("class", "info-text expertise")
      .attr("startOffset", "50%")
      .text(this.settings.copy.expertise);

    // Add count
    this.count = select("#count");
    this.count.text(
      `Getoond: ${this.entities.length} organisaties (van ${this.entities.length})`
    );

    // Create the groups
    this.linkGroup = this.svg.append("g").attr("class", "link-group");
    this.nodeGroup = this.svg.append("g").attr("class", "node-group");

    // Create a layer which contains the html labels
    this.htmlLabels = this.container.append("div").attr("class", "html-labels");

    // Add hover label
    this.hoverLabel = this.htmlLabels
      .append("div")
      .attr("class", "hover-label-container hide");

    // Start off at 0 opacity
    this.linkGroup.attr("opacity", 0);
    this.nodeGroup.attr("opacity", 0);
    this.htmlLabels.style("opacity", 0);

    // Init force
    this.initializeForce();

    // Init the nodes with the force data
    this.initializeNodes();

    // Init list
    this.initializeList();

    // Update force
    this.updateForce();
  }

  initializeForce() {
    this.force = forceSimulation(this.data.nodes).velocityDecay(0.66).stop();
  }

  updateForce() {
    const radius =
      this.radius * this.settings.circles.innerCircle -
      this.settings.circles.padding;

    this.force
      .force("charge", forceManyBody())

      .force(
        "link",
        forceLink(this.data.links)
          .id((d) => {
            return d.id;
          })
          .distance((d) => {
            // We can determine the distance based on the nodes
            return (this.settings.nodes.padding + d.source.r + d.target.r) * 2;
          })
          .strength(() => {
            return 0.33;
          })
      )
      .force(
        "collide",
        forceCollide()
          .radius((d) => {
            return d.r + this.settings.nodes.padding;
          })
          .iterations(4)
          .strength(0.5)
      )
      .force(
        "x",
        forceX(() => {
          return this.width / 2;
        }).strength(() => {
          return 0.9;
        })
      )
      .force(
        "y",
        forceY(() => {
          return this.height / 2;
        }).strength(() => {
          return 0.9;
        })
      )
      .on("tick", () => {
        //Update the nodes
        this.nodeGroup.selectAll(".node").attr("transform", (d) => {
          // Check if should be
          if (d.fixed) {
            const position = calculateTrigonometric(
              { x: this.width / 2, y: this.height / 2 },
              d.angle_index,
              radius
            );
            d.fx = position.x;
            d.fy = position.y;
          }
          return `translate(${d.x},${d.y})`;
        });

        // Update links
        this.linkGroup
          .selectAll(".link")
          .attr("x1", (d) => d.source.x)
          .attr("y1", (d) => d.source.y)
          .attr("x2", (d) => d.target.x)
          .attr("y2", (d) => d.target.y);

        this.htmlLabels
          .selectAll(".label-container")
          .style("transform", (d) => {
            return `translate(${d.x}px,${d.y}px)`;
          });
      })
      .alpha(0.1)
      .restart();
  }

  updateNodeRadius() {
    // This is the allowed space
    const allowedRadius =
      this.radius * this.settings.circles.innerCircle -
      this.settings.circles.padding;

    // This total weight that needs to fit in the allowed space
    const weightSum = sum(
      // Filter out the expertise and social challenge nodes
      this.data.nodes.filter((d) => {
        return !d.fixed;
      }),
      (d) => {
        return d.weight;
      }
    );

    // Calculate scale factor; min: minScaleFactor max maxScaleFactor
    const scaleFactor = min([
      max([
        this.settings.nodes.minScaleFactor,
        (allowedRadius / weightSum) * this.settings.nodes.radius.scaleFactor,
      ]),
      this.settings.nodes.maxScaleFactor,
    ]);

    // Determine scale
    this.radiusScale
      .domain([
        min(this.data.nodes, (d) => {
          return d.weight;
        }),
        max(this.data.nodes, (d) => {
          return d.weight;
        }),
      ])
      .range([
        this.settings.nodes.radius.min * scaleFactor,
        this.settings.nodes.radius.max * scaleFactor,
      ]);

    this.data.nodes.forEach((node) => {
      // Set idle radius easier to control the hover events and resetting back to the original radius
      node.idleRadius =
        node.type === "social_challenge" || node.type === "expertise"
          ? this.settings.nodes.radius.topic
          : this.radiusScale(node.weight);
      node.r = node.idleRadius;
    });
  }

  initializeList() {
    select("#resultsList")
      .selectAll(".list-entry")
      .data(() => this.entities.sort((a, b) => a.name.localeCompare(b.name)))
      .join("li")
      .append("button")
      .attr("id", (d) => `list-entry-${d.id}`)
      .on("click", (event, d) => {
        this.force.stop();

        // Set hove radius
        d.r = d.idleRadius + this.settings.nodes.radius.hover;

        this.clickNode(d);

        // Set hover label
        this.updateLabel(d);

        // Highlight the nodes and links
        this.updatedRelatedHoverNodes(d);

        d.r = d.idleRadius;
      })
      .text((d) => d.name)
      .classed("list-button", true);
  }

  populateList(nodes) {
    select("#resultsList").selectAll("*").remove();
    const listItems = nodes
      ? select("#resultsList").selectAll(".list-entry").data(nodes).join("li")
      : select("#resultsList")
          .selectAll(".list-entry")
          .data(this.entities)
          .join("li");

    listItems
      .append("button")
      .attr("id", (d) => `list-entry-${d.id}`)
      .on("click", (event, d) => {
        this.force.stop();

        // Set hove radius
        d.r = d.idleRadius + this.settings.nodes.radius.hover;

        this.clickNode(d);

        // Set hover label
        this.updateLabel(d);

        // Highlight the nodes and links
        this.updatedRelatedHoverNodes(d);

        d.r = d.idleRadius;
      })
      .text((d) => d.name)
      .classed("list-button", true);
  }

  initializeNodes() {
    // Update the node radius depending on the resolution of the screen
    this.updateNodeRadius();

    // Add labels
    const label = this.htmlLabels
      .selectAll(".label-container")
      // Filter only non-company nodes
      .data(
        this.data.nodes.filter((d) => {
          return d.type === "social_challenge" || d.type === "expertise";
        })
      )
      .join("div")
      .attr("class", (d) => `label-container ${d.type}`);

    label
      .append("div")
      .attr("class", "label")
      .text((d) => d.name);

    // Add nodes
    const node = this.nodeGroup
      .selectAll(".node")
      .data(this.data.nodes, (d) => {
        // Set defaults
        d.opacity = this.settings.nodes.opacity.idle;

        // Toggle for when filtered
        d.isInteractive = true;
        d.isFilteredNode = false;
        d.isSelected = false;
      })
      .join("g")
      .attr("id", (d) => `node-${d.id}`)
      .attr("class", (d) => `node ${d.type}`);

    node
      .on("mouseenter", (event, d) => {
        if (d.type === "company" && d.isInteractive) {
          // Pause force
          this.force.stop();

          // Set hove radius
          d.r = d.idleRadius + this.settings.nodes.radius.hover;

          // Raise; much like z-index
          select(event.target).raise();

          // Because we raise the node; hover will not work correctly therefore we have to set a class
          select(event.target).classed("hovered", true);

          // Set hover label
          this.updateLabel(d);

          // Highlight the nodes and links
          this.updatedRelatedHoverNodes(d);
        }
      })
      .on("mouseleave", (event, d) => {
        if (d.type === "company" && d.isInteractive) {
          // Resume force
          this.force.restart();

          // Reset
          d.r = d.idleRadius;

          // Remove the hovered class
          select(event.target).classed("hovered", false);

          // Reset label
          this.updateLabel(null);

          // Reset view with the selected filters
          this.updateRelatedFilterNodes();
        }
      })
      .on("click", (event, d) => {
        if (d.type === "company" && d.isInteractive) {
          this.clickNode(d);
        } else if (d.type === "social_challenge" || d.type === "expertise") {
          this.setFilter(d);
        }
      });

    // Add backdrop circle for expertise and social_challenge
    node
      .filter((d) => {
        return d.type === "social_challenge" || d.type === "expertise";
      })
      .append("circle")
      .attr("class", "backdrop-circle")
      .attr("r", (d) => d.r);

    // Add circle
    node
      .append("circle")
      .attr("class", "colored-circle")
      .attr("r", 0)
      .attr("opacity", 0);

    // Add links
    this.linkGroup
      .selectAll(".link")
      .data(this.data.links, (d) => {
        // Set defaults
        d.isHighlighted = false;
        d.isFilteredLink = false;
        d.opacity = this.settings.links.opacity.idle;
      })
      .join("line")
      .attr("class", "link")
      .attr("stroke-opacity", 0);

    // Fade in layers
    this.linkGroup.transition().duration(1000).attr("opacity", 1);
    this.nodeGroup.transition().duration(1000).attr("opacity", 1);
    this.htmlLabels.transition().delay(1000).duration(1000).style("opacity", 1);

    // Init draw with an animation
    this.draw(500, 500);
  }

  setFilter(selectedFilter) {
    // Deselect
    this.selectNode(null);

    // Hide node info
    this.clickNode(null);

    // Set filter ids
    if (this.state.filter[selectedFilter.type] === null) {
      this.state.filter[selectedFilter.type] = selectedFilter.id;
    } else {
      if (this.state.filter[selectedFilter.type] === selectedFilter.id) {
        this.state.filter[selectedFilter.type] = null;
      } else {
        this.state.filter[selectedFilter.type] = selectedFilter.id;
      }
    }

    // Update the nodes with the correct filters
    this.updateRelatedFilterNodes();

    const relatedNodeData = this.entities.filter((x) =>
      this.parent.relatedNodeIds.includes(x.id)
    );

    this.populateList(relatedNodeData);
  }

  updatedRelatedHoverNodes(selectedNode) {
    const relatedNodes = selectedNode.related_nodes;

    // Set opacity per node
    this.data.nodes.forEach((d) => {
      // Only for company
      if (d.type === "company") {
        const isRelated =
          (relatedNodes.includes(d.id) || selectedNode.id === d.id) &&
          d.isInteractive;

        isRelated
          ? (d.opacity = this.settings.nodes.opacity.active)
          : d.isFilteredNode
          ? (d.opacity = this.settings.nodes.opacity.inactiveFiltered)
          : (d.opacity = this.settings.nodes.opacity.inactive);
      }
    });

    // Update the links
    this.setLinksOpacity(selectedNode);

    // Update content
    this.draw(0, 0);
  }

  updateRelatedFilterNodes() {
    // Reset
    if (
      this.state.filter.social_challenge === null &&
      this.state.filter.expertise === null &&
      this.state.filter.role === null &&
      this.state.filter.region === null
    ) {
      this.resetNodesInteractivity();
      this.parent.relatedNodeIds = this.entities.map((d) => d.id);
      this.search.data.src = this.entities;

      select("#modalButton").classed("disabled-button", false);

      this.count.text(
        `Getoond: ${this.entities.length} organisaties (van ${this.entities.length})`
      );
    } else {
      // Update content
      const relatedNodes = this.getSelectedIds(
        this.state.filter.social_challenge,
        this.state.filter.expertise,
        this.state.filter.role,
        this.state.filter.region
      );
      this.parent.relatedNodeIds = relatedNodes;

      // Disabling list modal button
      select("#modalButton").classed("disabled-button", !relatedNodes.length);

      this.setNodesInteractivity(relatedNodes);
      const relatedNodeData = this.entities.filter((x) =>
        relatedNodes.includes(x.id)
      );
      this.search.data.src = relatedNodeData;

      this.count.text(
        `Getoond: ${relatedNodes.length} organisaties (van ${this.entities.length})`
      );
    }
  }

  fetchRelatedNodes(nodeId) {
    return this.data.nodes.filter((d) => {
      return d.id === nodeId;
    })[0].related_nodes;
  }

  fetchRoleFilterNodes(roleFilterNodeId) {
    return this.data.filters.roles.filter((d) => {
      return d.id === roleFilterNodeId;
    })[0].related_nodes;
  }

  fetchRegionFilterNodes(regionFilterNodeId) {
    return this.data.filters.regions.filter((d) => {
      return d.id === regionFilterNodeId;
    })[0].related_nodes;
  }

  getSelectedIds(
    selectedChallengeId,
    selectedExpertiseId,
    roleFilterId,
    regionFilterId
  ) {
    // Get array of individual selected nodes
    const individualSelection = [];

    // Add challenge nodes array
    if (selectedChallengeId !== null) {
      individualSelection.push(this.fetchRelatedNodes(selectedChallengeId));
    }
    // Add expertise nodes array
    if (selectedExpertiseId !== null) {
      individualSelection.push(this.fetchRelatedNodes(selectedExpertiseId));
    }
    // Add role nodes array
    if (roleFilterId !== null) {
      individualSelection.push(this.fetchRoleFilterNodes(roleFilterId));
    }
    // Add region nodes array
    if (regionFilterId !== null) {
      individualSelection.push(this.fetchRegionFilterNodes(regionFilterId));
    }

    // Find nodes that occur in all individual arrays
    let resultNodes = [];
    for (var i = 0; i < individualSelection.length; i++) {
      if (i === 0) {
        resultNodes = individualSelection[i];
      } else {
        // Store the intersection of the current resultNodes and individualSelection array
        // in resultNodes, resulting
        resultNodes = resultNodes.filter(
          (e) => individualSelection[i].indexOf(e) !== -1
        );
      }
    }
    return resultNodes;
  }

  resetNodesInteractivity() {
    this.data.nodes.forEach((d) => {
      if (d.type === "company") {
        d.isInteractive = true;
        d.isFilteredNode = false;
      } else {
        d.isFilteredNode = false;
      }
    });
    this.setNodesOpacity(null);
  }

  setNodesInteractivity(relatedNodes) {
    // Set nodes interactivity allowance
    this.data.nodes.forEach((d) => {
      if (d.type === "company") {
        const isRelated = relatedNodes.includes(d.id);
        // Set active
        if (isRelated) {
          d.isInteractive = true;
          d.isFilteredNode = true;
        } else {
          d.isInteractive = false;
          d.isFilteredNode = false;
        }
      } else {
        // Check which filter node to set filtered; if so highlight links between
        if (
          this.state.filter.expertise !== null ||
          this.state.filter.social_challenge !== null
        ) {
          if (
            this.state.filter.expertise === d.id ||
            this.state.filter.social_challenge === d.id
          ) {
            d.isFilteredNode = true;
          } else {
            d.isFilteredNode = false;
          }
        } else {
          d.isFilteredNode = false;
        }
      }
    });

    // Set the opacity
    this.setNodesOpacity(relatedNodes);
  }

  setNodesOpacity(relatedNodes) {
    // Set opacity per node
    this.data.nodes.forEach((d) => {
      // Update the company nodes
      if (d.type === "company") {
        // If not null (if null === reset)
        if (relatedNodes !== null) {
          const isRelated = relatedNodes.includes(d.id) && d.isInteractive;

          isRelated
            ? (d.opacity = this.settings.nodes.opacity.active)
            : d.isFilteredNode
            ? (d.opacity = this.settings.nodes.opacity.inactiveFiltered)
            : (d.opacity = this.settings.nodes.opacity.inactive);
        } else {
          d.opacity = this.settings.nodes.opacity.idle;
        }
      } else {
        // Update the other nodes
        if (
          this.state.filter.expertise !== null ||
          this.state.filter.social_challenge !== null
        ) {
          if (
            this.state.filter.expertise === d.id ||
            this.state.filter.social_challenge === d.id
          ) {
            d.opacity = this.settings.nodes.opacity.active;
          } else {
            d.opacity = this.settings.nodes.opacity.inactiveFilter;
          }
        } else {
          d.opacity = this.settings.nodes.opacity.active;
        }
      }
    });

    // Update the links
    this.setLinksOpacity(relatedNodes);

    // Update the content
    this.draw(0, 0);
  }

  setLinksOpacity(selectedNode) {
    this.data.links.forEach((d) => {
      // Reset
      if (selectedNode === null) {
        d.opacity = this.settings.links.opacity.idle;
        d.isHighlighted = false;
      } else {
        // The current selected one
        const isSelected =
          (selectedNode.id === d.source.id && d.source.isInteractive) ||
          (selectedNode.id === d.target.id && d.target.isInteractive);

        const isFilteredLink =
          d.source.isFilteredNode && d.target.isFilteredNode;

        isSelected
          ? (d.opacity = this.settings.links.opacity.active)
          : isFilteredLink
          ? (d.opacity = this.settings.links.opacity.inactiveFiltered)
          : (d.opacity = this.settings.links.opacity.idle);

        // Determine if to add highlighted link class
        d.isHighlighted = isSelected;
        d.isFilteredLink = isFilteredLink;
      }
    });
  }

  draw(animationDelay = 0, animationDuration = 0) {
    // When no animation needed just hard set
    if (animationDelay === 0 && animationDuration === 0) {
      this.nodeGroup
        .selectAll(".node")
        .classed("isInteractive", (d) => d.isInteractive)
        .classed("isFilteredNode", (d) => d.isFilteredNode)
        .classed("isSelected", (d) => d.isSelected)
        .select(".colored-circle")
        .attr("r", (d) => d.r)
        .attr("opacity", (d) => d.opacity);
      this.linkGroup
        .selectAll(".link")
        .classed("isHighlighted", (d) => d.isHighlighted)
        .classed("isFilteredLink", (d) => d.isFilteredLink)
        .attr("stroke-opacity", (d) => d.opacity);
    } else {
      // Animate in
      this.nodeGroup
        .selectAll(".node")
        .classed("isInteractive", (d) => d.isInteractive)
        .classed("isFilteredNode", (d) => d.isFilteredNode)
        .classed("isSelected", (d) => d.isSelected)
        .select(".colored-circle")
        .transition()
        .delay(animationDelay)
        .ease(easeQuadIn)
        .duration(animationDuration)
        .attr("r", (d) => d.r)
        .attr("opacity", (d) => d.opacity);
      this.linkGroup
        .selectAll(".link")
        .classed("isHighlighted", (d) => d.isHighlighted)
        .classed("isFilteredLink", (d) => d.isFilteredLink)
        .transition()
        .delay(animationDelay)
        .ease(easeQuadIn)
        .duration(animationDuration)
        .attr("stroke-opacity", (d) => d.opacity);
    }
  }

  updateLabel(hoveredNode) {
    if (
      hoveredNode === null ||
      hoveredNode.type === "social_challenge" ||
      hoveredNode.type === "expertise"
    ) {
      this.hoverLabel.classed("hide", true);
    } else {
      const positionLeft = hoveredNode.x > this.width / 2;
      const translateX = positionLeft
        ? hoveredNode.x - hoveredNode.r
        : hoveredNode.x + hoveredNode.r;
      this.hoverLabel.classed("hide", false);
      this.hoverLabel.style("transform", () => {
        return `translate(${translateX}px, ${hoveredNode.y}px)`;
      });

      // Set content
      const html = `
				<div class="hover-label ${positionLeft ? "left-label" : ""}">
          <div class="name">${hoveredNode.name}</div>
          <div class="role">${
            hoveredNode.role === null ? "–" : hoveredNode.role
          }</div>
				</div>
			`;
      this.hoverLabel.html(html);
    }
  }

  selectNode(selectedNode) {
    this.selectedNode = selectedNode;

    // Reset
    select("#resultsList").selectAll(".list-button").classed("selected", false);
    this.nodeGroup.selectAll(".node").classed("hovered", false);
    this.setNodesInteractivity(this.parent.relatedNodeIds);
    this.updateLabel(null);

    // Make sure all other nodes do not have the selected class
    this.data.nodes.forEach((node) => {
      node.isSelected = false;
    });

    // Set selected to true
    if (selectedNode !== null) {
      selectedNode.isSelected = true;
      select(`#list-entry-${selectedNode.id}`).classed("selected", true);
      // Raise; much like z-index
      select(`#node-${selectedNode.id}`).raise();

      // Because we raise the node; hover will not work correctly therefore we have to set a class
      select(`#node-${selectedNode.id}`).classed("hovered", true);
    }
  }

  clickNode(selectedNode) {
    if (this.search.input.value !== selectedNode?.name) {
      document.getElementById("autoComplete").value = "";
    }

    // highlight
    this.selectNode(selectedNode);

    // Update side panel with information
    this.parent.updateSidePanel(selectedNode);

    // Update
    this.draw();
  }

  resize(width, height) {
    this.width = Math.min(width, height);
    this.height = Math.min(width, height);
    this.radius = Math.min(this.width / 2, this.height / 2);

    // Resize canvas
    this.svg.attr("width", this.width).attr("height", this.height);
    this.svg
      .select(".outer-circle")
      .attr(
        "r",
        this.radius * this.settings.circles.outerCircle -
          this.settings.circles.padding
      )
      .attr("transform", () => {
        return `translate(${this.width / 2}, ${this.height / 2})`;
      });
    this.svg
      .select(".inner-circle")
      .attr(
        "r",
        this.radius * this.settings.circles.innerCircle -
          this.settings.circles.padding
      )
      .attr("transform", () => {
        return `translate(${this.width / 2}, ${this.height / 2})`;
      });

    // Update text paths
    this.svg.select("#upperTextPath").attr("d", () => {
      const textHeightOffset = (16 / 3) * 2;
      const r =
        this.radius * this.settings.circles.textPathCircle -
        (this.settings.circles.padding + textHeightOffset);
      return `M
			${this.width / 2 - r},${this.height / 2}
			A ${r},${r} 0 0,1
			${this.width / 2 + r},${this.height / 2}`;
    });
    this.svg.select("#lowerTextPath").attr("d", () => {
      const r =
        this.radius * this.settings.circles.textPathCircle -
        this.settings.circles.padding;
      return `M
				${this.width / 2 - r},${this.height / 2}
				A ${r},${r} 0 0,0
				${this.width / 2 + r},${this.height / 2}`;
    });

    // Center title
    this.svg.select("#title").attr("transform", () => {
      // Font-Size / 3  * 2 will correct the font offset
      const textHeightOffset = (16 / 3) * 2;
      return `translate(${this.width / 2},${
        this.settings.circles.padding + textHeightOffset
      })`;
    });

    // Update node radius depending on new screen resolution
    this.updateNodeRadius();
    this.draw();

    // Update with the new width and height
    this.updateForce();
  }
}

export default NetworkVisualization;
