const calculateTrigonometric = (center, angle, distance) => {
	return {
		x: center.x + Math.cos(angle) * distance,
		y: center.y + Math.sin(angle) * distance,
	};
};

export default calculateTrigonometric;

// export default Trigonometric = ({ center }, angle, distance) => {
// 	const offset = Math.PI / 2;
// 	return {
// 		x: center.x + Math.cos(angle - offset) * distance,
// 		y: center.y + Math.sin(angle - offset) * distance,
// 	};
// };
