import { select } from "d3";

const tableColumns = {
  name: "Organisatie",
  region: "Regio",
  social_challenge: "Markt",
  role: "Rol",
};

const exportColumns = {
  name: "Organisatie",
  expertise: "Enabler",
  region: "Regio",
  social_challenge: "Markt",
  role: "Rol",
};

export default class Modal {
  constructor() {
    this.modal = select("#modal");
    this.table = select("#results-table");
    this.header = select("#table-header");
    this.body = select("#table-body");
    this.filters = select("#list-filters");

    this.nodes = null;

    select("#download-form").on("submit", (e) => this.submit(e));
    select("#close-button").on("click", () => this.close());

    this.header
      .insert("tr")
      .attr("class", "headers")
      .selectAll("th")
      .data(Object.values(tableColumns))
      .join("th")
      .text((d) => d);
  }

  open(nodes, filters, total) {
    if (!nodes?.length) {
      return null;
    }

    this.nodes = nodes;

    document.getElementById("input-filters").value = Object.entries(filters)
      .map(([key, value]) => `${key}:${value ? value : "ALL"}`)
      .join("&");

    Object.entries(filters).map(([key, value]) => {
      if (value) {
        this.filters.append("span").text(`${tableColumns[key]}: ${value}`);
      }
    });

    this.filters
      .append("span")
      .text(`Getoond: ${nodes.length} organisaties (van ${total})`);

    const rows = this.body.selectAll(".entity-row").data(nodes).join("tr");
    Object.keys(tableColumns).forEach((key) => {
      rows
        .append("td")
        // Type is social_challenge but value is challenges
        .text((d) => (key === "social_challenge" ? d.challenges : d[key]));
    });

    this.modal.classed("hidden", false);
  }

  close() {
    this.modal.classed("hidden", true);
    this.body.selectAll("*").remove();
    this.filters.selectAll("*").remove();
    document.getElementById("input-filters").value = "";
  }

  // NOTE: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  submit(e) {
    e.preventDefault();

    const content = this.nodes.map((node) =>
      Object.keys(exportColumns).map((key) => {
        const value = key === "social_challenge" ? node.challenges : node[key];
        return Array.isArray(value) ? `"${value.join(",")}"` : value;
      })
    );

    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.values(exportColumns).join(",") +
      "\n" +
      content.map((d) => d.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "organisaties.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }
}
